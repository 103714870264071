import React, {useContext, useEffect} from "react";
import styles from "./Main.module.css";
import Loading from "../molecules/Loading";
import useAppStore from "../hooks/useAppStore";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import * as utils from "../utils/materials";
import { useTranslation } from "react-i18next";
import Readonly from "../templates/Readonly";


const materialName = (paint, material) => {
  
  if(! paint) return `${material} Shiny`
  
  return paint.configuration.id === 'matt'
    ? `${material} Matt`
    : `${material} Shiny`
}

export default function Viewer({
  ...props
 }) {

  const {viewer, loaded, progress} = useContext(EmersyaViewerContext);
  
  const { t } = useTranslation();
  
  const { currentMaterials, saddleConfiguration } = useAppStore( (state) => ({
    currentMaterials: state.currentMaterials,
    saddleConfiguration: state.saddleConfiguration,
  }));
  
  async function updateCustomText(saddleConfiguration, side) {
    
    if(
      saddleConfiguration.text === '' ||
      saddleConfiguration.textColor === null
    ) return Promise.resolve(true);
    
    await viewer.updateCustomTextsRealDimensions({
      configurableMaterial: `custom_text_${side}`,
      materialVariation: 'custom_text',
      contents : ['opacity', 'albedo'],
      color : '#ffffffff',
      backgroundColor : '#00000000',
      imageWidth : 2048,
      verticalAlignment : 'middle',
      textAlignment : 'static',
      horizontalAlignment : side === 'right' ? 'left' : 'right',
      textOffsetX : 0,
      textOffsetY : 0,
      uvRatio : 10.34,
      surfaceWidth :190.35,
      maxTextWidth :190,
      maxTextHeight :10,
      font : 'OpenSans-Semibold',
      mode : 'vertical',
      texts : [{
        color : saddleConfiguration.textColor.color,
        verticalAlignment : 'middle',
        horizontalAlignment : 'middle',
        offsetY : 0,
        offsetX : 0,
        text : saddleConfiguration.text,
        font : 'OpenSans-Semibold',
        prespacing : 0,
        postspacing : 0
      }]
    });
    
    return Promise.resolve(true);
  }
  
  async function applySignature(saddleConfiguration, side) {
    
    //Mario Cipollini signature
    if (
      saddleConfiguration.signatureColor !== null &&
      saddleConfiguration.signatureColor !== undefined
    ) {
      return viewer.setMaterials({
        materials: [{
          configurableMaterial: `signature_patch_${side}`,
          materialVariation: `mario_cipollini_${saddleConfiguration.signatureColor.name}`,
        }]
      });
    }
    
    return Promise.resolve(true);
    
  }
    //Custom image and Custom text
  async function applyCustomImage(saddleConfiguration, side) {
    
    if(saddleConfiguration.base64 !== null) {
      await viewer.setMaterials({
        materials: [{
          configurableMaterial: `signature_patch_${side}`,
          materialVariation: 'custom',
        }]
      });
      
      await viewer.updateCustomTexture({
        configurableMaterial: `signature_patch_${side}`,
        materialVariation: 'custom',
        image: saddleConfiguration.base64,
        contents: ['baseColor', 'opacity']
      });
      
    }
    
    return Promise.resolve(true);
  }
  
  async function applyMaterials(materials){
    
    const paint = utils.getMaterialByGroupId(materials, 'paint');
    
    const filteredCurrentMaterial = materials.filter(material => (
      material.group.id !== 'design' &&
      material.group.id !== 'paint' &&
      material.group.id !== 'text-image' &&
      ['color'].indexOf(material.group.selectorType) >= 0)
    );
    
    let extraMaterialGroups = [];
    const design = utils.getMaterialByGroupId(materials, 'design');
    
    // monocromo design require that Telaio (Main Color) and Linee (Level color 3°) have the same color
    if(
      design &&
      design.configuration.id === 'monocromo'
    ) {
      const mainColor = utils.getMaterialByGroupName(materials, 'Telaio');
      extraMaterialGroups = [{
        configurationName: materialName(paint, mainColor.configuration.name),
        groupName: 'Linee'
      },{
        configurationName: materialName(paint, mainColor.configuration.name),
        groupName: 'Stickers'
      }
      ];
    }
    
    // Spectraflair design
    if(
      design &&
      design.configuration.id === 'spectaflair'
    ) {
      extraMaterialGroups = [{
        configurationName: 'Spectraflair 1',
        groupName: 'Telaio'
      }, {
        configurationName: 'Spectraflair 1',
        groupName: 'Linee'
      },{
        configurationName: 'Spectraflair 1',
        groupName: 'Stickers'
      }];
    }
    
    await viewer.setMaterialsGroups({
      configurations: filteredCurrentMaterial.map(material => ({
        configurationName: materialName(paint, material.configuration.name),
        groupName: material.group.name,
      })).concat(extraMaterialGroups)
    });
    
    await applyCustomImage(saddleConfiguration.right, 'right');
    await updateCustomText(saddleConfiguration.right, 'right');
    
    await applyCustomImage(saddleConfiguration.left, 'left');
    await updateCustomText(saddleConfiguration.left, 'left');
    
    // this call have to be the last one, otherwise it's not work
    // sometimes emersya api does not have time to set the signature (see https://reloadhub.atlassian.net/browse/DMT-127)
    // so we have to introduce a delay with a setTimeout
    setTimeout(function(){
      applySignature(saddleConfiguration.right, 'right');
    }, 300);
    
    return Promise.resolve(true);
  }
  
  useEffect(() => {
    if(viewer === null) return;
    if(loaded === false) return;

    if(currentMaterials.length === 0) return;
    
    // apply Matt or Shiny color according to paint value
    applyMaterials(currentMaterials).then( result => console.log(result));

  }, [viewer, loaded]);
  
  let configuratorClassName = `${styles.configurator} ${loaded === true ? styles.loaded : null}`
  
  return (
    <Readonly>
      <div id="cipollini3dConfigurator" className={configuratorClassName}  />
      {loaded === false &&  (
        <div className={styles.overlay}>
          <div className={styles.loadingContainer}>
            <Loading progress={progress} />
          </div>
        </div>
      )}
    </Readonly>
  );
}
